import { useState } from "react"
import styled from "styled-components"

import { IconButton } from "@material-ui/core"

import { shadowBottom } from "src/constants/shadows"
import { Z_INDEX_APP_BAR } from "src/constants/zindex"
import { backgroundColor, brandText } from "src/ui/colors"
import CloseIcon from "src/ui/icons/close.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type TMaxWidth = "small" | "medium" | string

export function FullscreenView({
  title,
  onClose,
  children,
  maxWidth = "medium",
}: {
  title?: React.ReactNode
  onClose?: () => void
  children: React.ReactNode
  maxWidth?: TMaxWidth
}) {
  const [showTopBorder, setShowTopBorder] = useState(false)

  function handleScroll(e: React.UIEvent<HTMLElement>) {
    const { scrollTop } = e.currentTarget
    setShowTopBorder(scrollTop !== 0)
  }

  return (
    <FullScreenBox onScroll={handleScroll}>
      <Header $showBorder={showTopBorder}>
        <HeaderContent $maxWidth={maxWidth}>
          <MText variant="heading2">{title}</MText>
          {!!onClose && (
            <IconButton size="small" edge="end" onClick={onClose}>
              <CloseIcon width={24} color={brandText} />
            </IconButton>
          )}
        </HeaderContent>
      </Header>

      <Content $maxWidth={maxWidth}>{children}</Content>
    </FullScreenBox>
  )
}

const Header = styled.header<{ $showBorder: boolean }>`
  position: sticky;
  top: 0;
  background-color: ${backgroundColor};
  z-index: ${Z_INDEX_APP_BAR};
  width: 100%;
  ${(props) => props.$showBorder && shadowBottom};
`

const Content = styled.section<{ $maxWidth: TMaxWidth }>`
  padding: ${spacing.M};
  width: 100%;
  max-width: ${({ $maxWidth }) => {
    switch ($maxWidth) {
      case "small":
        return "65ch"
      case "medium":
        return "75ch"
      default:
        return $maxWidth
    }
  }};
`

const HeaderContent = styled(Content)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  & > :first-child {
    flex: 1 1 100%;
  }
`

const FullScreenBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  /* In order to be able to give the top bar a shadow on scroll, we need to set a
   * max height on this container. */
  max-height: 99vh;
  overflow: auto;
`
